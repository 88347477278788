<template lang="html">
  <div v-resize="onResize">
    <div class="activitiesPage">
      <div class="activities" v-if="!hideActivities">
        <div class="activitiesHeader" v-if="!hideActivities" :class="{'isUserSize':isUser,'isObservatorSize':isObservator}">
          <div class="activitiesHeader__title" ref="activitiesHeaderTitle" v-show="activitiesCollapsed">
            
            <div class="activitiesHeader__title--container">
              <a v-on:click="activitiesBoxCollapsed()">{{$t('activities')}}
                <!--i class="fa fa-sort-down" v-if="activitiesCollapsed"></i>
                  <i class="fa fa-sort-up" v-if="!activitiesCollapsed"></i-->
                </a>
                <label  @click="toogleCheckedActivityFilter" class="checkbox-label">
                  <input type="checkbox" v-model="filterChecked" class="hidden-checkbox">
                  <span @click="toogleCheckedActivityFilter" class="label-text">Fecha inicio</span>
                  <i @click="toogleCheckedActivityFilter" v-if="filterChecked" class="fas fa-arrow-up"></i>
                  <i @click="toogleCheckedActivityFilter" v-else class="fas fa-arrow-down"></i>
                </label>
              </div>
                
        
              <router-link
              :to="{ name: 'activities.add', params: { origin } }"
              :class="{'communityRevision--btn':communityInfo.status == 'REVISION'}"
              class="activitiesHeader__title--addActivity" v-if="isCommunityAdmin"
              event=""
              @click.native.prevent="goCreateActivity({ name: 'activities.add', params: { origin } },$event)">{{$t('action_activity_create')}}</router-link>
            </div>
        </div>
        
        <Tabs class="activities__Status" :tabs="orderFilters" :activeOnLoad="orderFilters[0].id" v-on:change="handleChangeOrderFilter" ref="TabsComponent" v-show="activitiesCollapsed" />
        <div class="activities__Spinner" v-if="showActivitiesSpinner">
            <i class="fas fa-spinner"></i>
        </div>
        <div class="activitiesReturn" v-show="!activitiesCollapsed" v-on:click="activitiesBoxCollapsed()">
          <i class="fas fa-arrow-left"></i>Volver al listado de actividades
        </div>
        <div class="activities__List" :class="{'isUserSize':isUser}" v-show="activitiesCollapsed">
          <router-link
            :to="{ name: activity.type == 'VIDEOCHAT' && activity.status == 'ACTIVE' ? 'activities.videochat' : 'activity.info', params: { origin, id: activity.identifier } }"
            class="activities__List__Activity"
            :class="{active : activity.identifier == communityCurrentActivityId}"
            v-for="activity in filterActivities"
            event=""
            @click.native="expandActivity"
            @click.native.prevent="checkActivityChange({ name: activity.type == 'VIDEOCHAT' && activity.status == 'ACTIVE' ? 'activities.videochat' : 'activity.info', params: { origin, id: activity.identifier } })">
            <div class="activities__List__Activity__Image" :data-content="$filters.translate($filters.formatActivityType(activity.type)).toUpperCase()">
              <img v-if="activity.type=='WAT_SURVEY' || activity.type=='EL_SURVEY'" src="@/assets/img/activities/survey-icon.svg">
              <img v-if="activity.type=='WAT_TASK'" src="@/assets/img/activities/task-icon.svg">
              <img v-if="activity.type=='WAT_CHAT'" src="@/assets/img/activities/chat-icon.svg">
              <img v-if="activity.type=='WAT_POLL'" src="@/assets/img/activities/poll-icon.svg">
              <img v-if="activity.type=='WAT_OPINION'" src="@/assets/img/activities/opinion-icon.svg">
              <img v-if="activity.type=='VIDEOCHAT'" src="@/assets/img/videoChatIcon.svg" class="videoChat-icon">
              <img v-if="activity.type=='EL_HEATMAP'" src="@/assets/img/activities/heatmap-icon.svg"/>
              <img v-if="activity.type=='WAT_UX'" src="@/assets/img/activities/watux-icon.svg"/>
            </div>
            <div class="activities__List__Activity__Info">
              <div class="activities__List__Activity__Title">{{activity.title}}</div>
              <h5 class="activities__List__Activity__Info__dates">{{$t('date_from_to2', { from: formatDateForTranslation(activity.dateFrom), to: formatDateForTranslation(activity.dateTo) })}}</h5>
              <div class="activities__List__Activity__Info__Progress__Bar" v-if="isClient">
                <progress-bar
                  :value="(activity.participatedTesterNum/activity.membersNum)*100"
                />
              </div>
              <div class="activities__List__Activity__Info__bottom">
                <p class="unseenMention" v-if="activity.mentionsUnseen > 0">@</p>
                <p class="unseen" v-if="activity.moderatorUnseen > 0 && isClient">{{activity.moderatorUnseen}}</p>
                <p class="participated" v-if="activity.userHasParticipated && isUser"><i class="fas fa-check-circle"></i></p>
                <p class="members"><i class="fa fa-user"></i>{{activity.membersNum}}</p>
                <span class="bar">|</span>
                <p class="type">{{ $filters.translate($filters.formatActivityType(activity.type)) }}</p>
                <span class="bar">|</span>
                <span class="status-circle" :class="{ active: activity.status == 'ACTIVE',inactive: activity.status == 'FINISHED' || activity.status == 'NEW' || activity.status == 'CONFIGURING' }"></span>
                <p :class="'status--'+activity.status">{{ $filters.translate($filters.formatActivityStatus(activity.status)) }}</p>
              </div>
            </div>
            <i class="fa fa-ellipsis-v more-options" id="ActivitiesListMoreOptions" v-on:click="showActions(activity, $event)" @touchstart="showActions(activity, $event)" v-if="isModerator"></i>
            <div class="more-options__container" v-if="activity.showActions">
              <a v-on:click="editActivity()"><i class="fas fa-pencil-alt"></i> {{$t('action_edit')}}</a>
              <a v-on:click="deleteActivity(activity.identifier)" ><i class="fa fa-trash"></i> {{$t('action_delete')}}</a>
              <a v-on:click="duplicateActivity(activity.identifier)" v-if="isCommunityAdmin"><i class="fa fa-clone"></i> {{$t('action_duplicate')}}</a>
              <a v-on:click="finishActivity(activity.identifier)" v-if="activity.status=='ACTIVE'"><i class="fa fa-check"></i> {{$t('action_finished')}}</a>
            </div>
          </router-link>
        </div>
      </div>
      
      <BoxShadow class="activitiesPage--noActivities" v-if="!communityActivities[0] && isClient && $route.name == 'activities'">
        {{$t('message_no_activities')}}
        <router-link
          :to="{ name: 'activities.add', params: { origin } }"
          :class="{'communityRevision--btn':communityInfo.status == 'REVISION'}"
          class="activitiesHeader__title--addActivity" v-if="isCommunityAdmin"
          event=""
          @click.native.prevent="goCreateActivity({ name: 'activities.add', params: { origin } },$event)">{{$t('action_activity_create')}}</router-link>
      </BoxShadow>
      <router-view v-if="responsiveActivityPreview" ref="routerViewRef" v-on:hide-header="$emit('hide-header',$event)" v-on:hide-sidebar="hideSidebar($event)"/>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Swal from "sweetalert2";
import { mapGetters, mapMutations } from "vuex";
import store from "@/store";
import resize from "vue-resize-directive";
import moment from "moment-timezone";
import { map, flatMap, isEmpty } from "lodash";
import ProgressBar from "@/components/ProgressBar.vue";
import $ from "jquery";
import Tabs from "~/components/Tabs";
import BoxShadow from "@/components/BoxShadow";

import {
  COMMUNITY_ADD_ACTIVITY,
  COMMUNITY_FETCH_ACTIVITIES,
  COMMUNITY_DELETE_ACTIVITY,
  COMMUNITY_FINISH_ACTIVITY,
  COMMUNITY_DUPLICATE_ACTIVITY,
} from "@/store/community/activities.module";

import { COMMUNITY_FETCH_MEMBERS } from "@/store/community/members.module";

export default {
  name: "ActivitiesPage",

  components: {
    Tabs,
    "progress-bar": ProgressBar,
    BoxShadow,
  },

  directives: {
    resize,
  },

  computed: {
    ...mapGetters([
      "communityInfo",
      "communityActivitiesUnseen",
      "communityActivities",
      "communityCurrentActivity",
      "communityCurrentActivityId",
      "isClient",
      "isModerator",
      "isObservator",
      "isCommunityAdmin",
      "isUser",
      "origin",
      "theme",
      "forceUpdateDefaultActivity",
    ]),
    responsiveActivityPreview() {
      // Verificar si el ancho de la pantalla es mayor que 768px o si activitiesCollapsed es verdadero
      return window.innerWidth > 768 || (window.innerWidth <= 768 && !this.activitiesCollapsed);
    },
    filterActivities() {
      return this.communityActivities.sort((a, b) => {
        const dateA = new Date(a.dateFrom);
        const dateB = new Date(b.dateFrom);
        return this.filterChecked ? dateA - dateB : dateB - dateA;
        console.log(this.filterActivities)
  });
    },
  },
  watch: {
    communityCurrentActivityId: function (val) {
      this.hideActivities = false;
      if (this.$route.name == "activities.add") {
        this.$refs.TabsComponent.reset();
      }
    },
    forceUpdateDefaultActivity: async function () {
      if (this.forceUpdateDefaultActivity == true) {
        this.setDefaultActivity(null);
        this.setForceUpdateDefaultActivity(false);
      }
    },

  },
  data() {
    return {
      goToEditActivity: false,
      isShowActions: false,
      orderFilters: [
        { id: "myActivities ", title: "activity_stat_my_activities" },
        { id: "finishedActivities", title: "activity_stat_finished2" },
      ],
      filterSelected: 0,
      activities: [],
      activitiesCollapsed: true,
      participants: [],
      progressBarOptions: {
        value: 25,
        background: "linear-gradient(to right,red, #b0040a)",
      },
      showActivitiesSpinner: false,
      hideActivities: false,
      filterChecked:ref(false),
    };
  },

  emits: ["full-screen", "hide-header"],
  mounted(){
    handleChangeOrderFilter()
  },
  async mounted() {
    if (this.isClient) {
      this.$el.addEventListener("click", this.onClickPage);
    }
    await this.fetchActivities("myActivities");
  },
  methods: {
    toogleCheckedActivityFilter(){
      this.filterChecked = !this.filterChecked
    },
    ...mapMutations({
      showActionsActivity: "showActionsActivity",
      restoreActivities: "restoreActivities",
      setForceUpdateDefaultActivity: "setForceUpdateDefaultActivity",
    }),
    onClickPage: function (e) {
      var container = $(".more-options");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        this.restoreActivities();
      }
    },

    showActions: function (activity, e) {
      e.preventDefault();
      this.isShowActions = true;
      this.restoreActivities();
      this.showActionsActivity(activity);
    },

    handleChangeOrderFilter: async function (priority) {
      try {
        this.showActivitiesSpinner = true;
      await this.fetchActivities(priority);
      if (this.$route.name == "activities" || priority != "myActivities") {
        this.setDefaultActivity(null);
      }
      } catch ({message}) {
        console.log(message);
      }finally{
        this.showActivitiesSpinner = false;
      }

      
    },

    setDefaultActivity: function (status) {
      if (status == "DELETED") {
        if (
          this.$route.name == "activity.info" &&
          this.communityActivities[0].type == "VIDEOCHAT" &&
          this.communityActivities[0].status == "ACTIVE"
        )
          this.$router.push({
            name: "activities.videochat",
            params: {
              origin: this.origin,
              id: this.communityActivities[0].identifier,
            },
          });
        else if (this.$route.name == "activity.info")
          this.$router.push({
            name: "activity.info",
            params: {
              origin: this.origin,
              id: this.communityActivities[0].identifier,
            },
          });
        return;
      }
      if (this.$route.params.id != undefined) return;
      if (!this.communityActivities[0]) return;
      if (
        this.$route.name == "activities" &&
        this.communityActivities[0].type == "VIDEOCHAT" &&
        this.communityActivities[0].status == "ACTIVE"
      )
        this.$router.push({
          name: "activities.videochat",
          params: {
            origin: this.origin,
            id: this.communityActivities[0].identifier,
          },
        });
      if (this.$route.name == "activities")
        this.$router.push({
          name: "activity.info",
          params: {
            origin: this.origin,
            id: this.communityActivities[0].identifier,
          },
        });
    },

    fetchActivities: async function (priority) {
      return await store.dispatch(COMMUNITY_FETCH_ACTIVITIES, { priority });
    },

    onResize: function () {
      if (window.innerWidth >= 769) {
        this.activitiesCollapsed = true;
      }
    },

    activitiesBoxCollapsed: function () {
      this.activitiesCollapsed = !this.activitiesCollapsed;
    },

    duplicateActivity: async function (activityId) {
      let vm = this;
      Swal.fire({
        title: vm.$t("msg_confirmation"),
        text: vm.$t("msg_activity_confirm_duplicate"),
        icon: "warning",
        cancelButtonText: vm.$t("action_cancel"),
        confirmButtonText: vm.$t("msg_confirm_duplicate"),
        showCancelButton: true,
        customClass: {
          popup: "swal-customWarning",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch(COMMUNITY_DUPLICATE_ACTIVITY, {
              activityId: activityId,
            })
            .then((data) => {
              this.fetchActivities("myActivities");
              Swal.fire(vm.$t("msg_activity_duplicated"));
            });
        }
      });
    },

    deleteActivity: async function (activityId) {
      let vm = this;
      Swal.fire({
        title: vm.$t("msg_confirmation"),
        text: vm.$t("msg_activity_confirm_delete"),
        icon: "warning",
        cancelButtonText: vm.$t("action_cancel"),
        confirmButtonText: vm.$t("msg_confirm_delete"),
        showCancelButton: true,
        customClass: {
          popup: "swal-customWarning",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch(COMMUNITY_DELETE_ACTIVITY, {
              activityId: activityId,
            })
            .then((data) => {
              this.fetchActivities("myActivities");
              this.setDefaultActivity("DELETED");
              Swal.fire(vm.$t("msg_activity_deleted"));
            });
        }
      });
    },

    finishActivity: async function (activityId) {
      let vm = this;
      Swal.fire({
        title: vm.$t("msg_confirmation"),
        text: vm.$t("msg_activity_confirm_finished"),
        icon: "warning",
        cancelButtonText: vm.$t("action_cancel"),
        confirmButtonText: vm.$t("msg_confirm_finished"),
        showCancelButton: true,
        customClass: {
          popup: "swal-customWarning",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch(COMMUNITY_FINISH_ACTIVITY, {
              activityId: activityId,
            })
            .then((data) => {
              this.fetchActivities("myActivities");
              this.setDefaultActivity(null);
              Swal.fire(vm.$t("msg_activity_finished"));
            });
        }
      });
    },

    expandActivity(event) {
      event.preventDefault();
      const isMobile = window.innerWidth < 769;

      if (isMobile) {
        this.activitiesCollapsed = false;
      }

      // setTimeout( (  ) => {
      //   this.$refs.activitiesHeaderTitle.scrollIntoView( {
      //     block: "start", behavior: "smooth"
      //   } );
      // }, 300 );
    },
    goCreateActivity(route, e) {
      e.preventDefault();
      if (this.communityInfo.status != "REVISION") this.$router.push(route);
    },
    checkActivityChange(route) {
      let vm = this;
      if (
        this.communityCurrentActivity.type == "VIDEOCHAT" &&
        this.communityCurrentActivity.status == "ACTIVE" &&
        this.$refs.routerViewRef != undefined &&
        this.$refs.routerViewRef.$children != undefined &&
        this.$refs.routerViewRef.$children[0].chatVideoCreated
      ) {
        Swal.fire({
          title: vm.$t("msg_confirmation"),
          text: vm.$t("msg_activity_videochat_exit"),
          icon: "warning",
          cancelButtonText: vm.$t("action_cancel"),
          confirmButtonText: vm.$t("msg_agree"),
          showCancelButton: true,
          customClass: {
            popup: "swal-customWarning",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            vm.$router.push(route);
          }
        });
      } else if (
        this.communityCurrentActivity.type == "WAT_CHAT" &&
        this.communityCurrentActivity.status == "ACTIVE" &&
        this.isUser &&
        this.communityCurrentActivity.debateTopicIds.length > 0 &&
        !this.communityCurrentActivity.userHasParticipated
      ) {
        Swal.fire({
          title: vm.$t("msg_confirmation"),
          text: vm.$t("msg_activity_wat_chat_required_topics"),
          icon: "warning",
          cancelButtonText: vm.$t("action_cancel"),
          confirmButtonText: vm.$t("msg_agree"),
          showCancelButton: true,
          customClass: {
            popup: "swal-customWarning",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            vm.$router.push(route);
          }
        });
      } else if (
        vm.isUser ||
        (vm.isClient && !vm.goToEditActivity && !vm.isShowActions)
      ) {
        vm.$router.push(route);
      } else if (this.isClient && this.goToEditActivity) {
        vm.goToEditActivity = false;
        vm.$router.push({
          name: "activities.edit",
          params: { id: route.params.id },
        });
      } else {
        vm.goToEditActivity = false;
        vm.isShowActions = false;
      }
    },
    editActivity() {
      this.goToEditActivity = true;
    },
    formatDateForTranslation(date) {
      if (date == undefined) {
        return "-";
      }
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    hideSidebar(ev) {
      this.hideActivities = ev;
    },
  },
};
</script>
<style lang="scss">
.activitiesPage {
  .activities__Status .Tab {
    padding: 10px;
    margin-right: 0;
    &--Active {
      background: white;
      font-weight: bold;
    }
  }
}
.checkbox-label {
  position: absolute;
  display: inline-flex;
  align-items: center;
  gap:5px;
  cursor: pointer;
  font-size: x-small;
  right: 0%;
  top: 50%;
  transform: translate(0, -50%);
}

.hidden-checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.label-text {
  margin-left: 0px;
}
</style>
<style lang="scss" scoped>
.activitiesHeader {
  display: flex;
  border-top: 1px solid #f1f1f1;
  background-color: white;
  min-width: 320px;
  width: 320px;
  &.isUserSize,
  &.isObservatorSize {
    height: 37px;
    @media screen and (max-width: 575px) {
      height: auto;
    }
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }

  a {
    cursor: pointer;
  }
  &__title {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    border-right: 1px solid #f1f1f1;
    align-items: center;
    min-width: 320px;
    width: 320px;
    font-size: 18px;
    justify-content: space-between;
    padding: 10px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: bold;
    @media screen and (max-width: 575px) {
      width: 100% !important;
    }

    &--addActivity {
      background: var(--accent-color);
      color: white;
      font-size: 14px;
      font-weight: normal;
      padding: 5px !important;
      margin-top: 5px;
      width: 100%;
      text-align: center;
      box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.14);
      border-radius: 5px;
      &:hover {
        opacity: 0.5;
      }
    }
    a {
      text-transform: uppercase;
      i {
        display: none;
      }
    }
    &--container{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
  }
  &__tabs {
    font-size: 14px;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    width: 100%;
    background: #f4f4f4;
    font-family: Roboto Condensed;
    font-style: normal;
    a {
      padding: 10px;
      color: #3e3e3e;
      font-size: 14px;
      &.selected {
        color: var(--primary-color);
        background: white;
        font-weight: bold;
      }
      span {
        font-weight: 600;
      }
    }
    @media screen and (max-width: 768px) {
      justify-content: center;
      background: white;
    }
  }
}
.activitiesPage {
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  &--noActivities {
    display: flex;
    flex-direction: column;
    margin: 5%;
    width: 100%;
    height: fit-content;
    padding: 50px;
    font-family: "Roboto Condensed";
    a {
      width: 200px !important;
      margin-top: 20px;
      font-size: 17px;
      text-transform: uppercase;
    }
  }
  .activities {
    background: white;
    min-width: 320px;
    width: 320px;
    border-right: 1px solid #f1f1f1;
    z-index: 1;
    font-family: Roboto Condensed;
    font-style: normal;
    &__Spinner {
      height: calc(100vh - 189px);
      position: absolute;
      background: white;
      width: 320px;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 600px) {
        height: 100vh;
        width: 100vw;
        margin:0;
  }
      i {
        font-size: 35px;
        animation: turn 1s infinite;
        @keyframes turn {
          from {
            transform: rotate(0deg);
            color: lightgray;
          }
          to {
            transform: rotate(360deg);
            color: var(--primary-color);
          }
        }
      }
    }
    &__unseen {
      display: flex;
      align-items: center;
      font-size: 0.9em;
      border-bottom: 1px solid #f1f1f1;
      i {
        padding: 10px;
        color: var(--primary-color);
      }
    }
    &__Status {
      background: #f4f4f4;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 44px;
      font-size: 12px;
      cursor: pointer;
      padding: 15px 15px 0 15px;
      text-transform: uppercase;
      .Tab {
        padding: 10px;
        margin-right: 0;
        &--Active {
          background: white;
          font-weight: bold;
        }
      }
    }
    &__List {
      height: calc(100vh - 216px);
      overflow-y: auto;
      border-bottom: 1px solid #f1f1f1;
      position: relative;
      &.isUserSize {
        height: calc(100vh - 180px);
      }
      &::-webkit-scrollbar-track {
        background-color: #e6e6e6;
      }
      &::-webkit-scrollbar {
        background-color: #8134ff;
        width: 3px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: var(--primary-color);
      }
      &__Activity {
        border-bottom: 1px solid #f1f1f1;
        display: flex;
        align-items: baseline;
        padding: 10px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        &.active::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: var(--primary-color);
          opacity: 0.1;
        }
        &:hover {
          border-left: 3px solid var(--primary-color);
        }
        .more-options {
          position: absolute;
          top: 0px;
          right: 0px;
          padding: 10px;
          color: #c8c8c8;
          z-index: 1;
          &__container {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 30px;
            right: 10px;
            z-index: 1;
            background: white;
            padding: 10px;
            border: 1px solid #f1f1f1;
            a {
              margin: 10px 0;
              border-bottom: 1px solid #f1f1f1;
              color: #4a4949;
            }
          }
        }
        &__Title {
          color: #3e3e3e;
          font-size: 16px;
          font-weight: bold;
        }

        &__Image {
          margin-left: 10px;
          width: 60px;
          height: 60px;
          &:hover {
            &:before {
              color: #3e3e3e;
              font-size: 12px;
              z-index: 1;
              left: 54px;
              top: 32px;
              content: attr(data-content);
              position: absolute;
              width: 105px;
              height: 35px;
              border-radius: 6px;
              background: #fff;
              padding: 0 10px;
              box-shadow: 5px 10px 30px rgba(65, 72, 86, 0.5);
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid var(--primary-color);
            }
            &:after {
              content: "";
              left: 60px;
              top: 43px;
              width: 12px;
              height: 14px;
              background: white;
              border: 1px solid var(--primary-color);
              position: absolute;
              margin-left: -10px;
              transform: rotate(135deg);
              z-index: 0;
              box-shadow: 0 10px 30px rgba(65, 72, 86, 0.5);
            }
          }
          img {
            width: 40px;
            height: 60px;
            padding: 5px;
          }
        }
        &__Info {
          width: 100%;
          padding: 0 10px;
          h5 {
            margin: 0;
            color: #4a4949;
          }
          &__dates {
            color: #3e3e3e !important;
            font-size: 13px;
            margin: 0;
            font-weight: normal;
          }
          &__Progress__Bar {
            margin: 10px 0;
            height: 10px;
          }
          &__bottom {
            display: flex;
            align-items: baseline;
            font-weight: normal;
            font-size: 13px;
            margin-top: 10px;
            text-transform: uppercase;
            p {
              margin: 0;
            }
            .bar {
              color: #3e3e3e;
              padding: 0 5px;
            }
            .type {
              color: #f6c07e;
              text-transform: uppercase;
            }
            .status {
              &--NEW,
              &--CONFIGURING {
                color: #9c9b9b;
                margin-left: 5px;
              }
              &--FINISHED {
                color: #9c9b9b;
                margin-left: 5px;
              }
              &--ACTIVE {
                color: #d7eaa9;
                margin-left: 5px;
              }
            }

            .members {
              color: var(--primary-color-text);
            }
            .unseenMention{
              position: absolute;
              top: 10px;
              right: 8px;
              background-color: var(--primary-color);
              width: 22px;
              height: 22px;
              display: flex;
              border-radius: 50%;
              font-weight: bold;
              color: #fff;
              justify-content: center;
              font-size: 12px;
              flex-direction: column;
              text-align: center;
              border: 2px solid #fff;
              z-index: 2;
            }
            .unseen {
              position: absolute;
              right: 8px;
              background-color: var(--primary-color);
              width: 22px;
              height: 22px;
              display: flex;
              border-radius: 50%;
              font-weight: bold;
              color: #fff;
              justify-content: center;
              font-size: 10px;
              flex-direction: column;
              text-align: center;
              border: 2px solid #fff;
              z-index: 2;
            }
            .participated {
              position: absolute;
              top: 5px;
              right: 8px;
              color: var(--primary-color);
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 769px) {
  .activitiesHeader {
    width: 100%;
    &__title {
      width: 100%;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          padding: 5px;
          display: block;
        }
      }
    }
  }
  .activitiesPage {
    .activities {
      // box-shadow: 0 10px 20px lightgrey;
      position: absolute;
      width: 100%;
      @media screen and (max-width: 768px) {
        height: auto;
        position: relative;
        .activities__List {
          height: auto;
        }
      }
    }
    .activity {
      width: 100%;
      &__Body {
        width: 95%;
      }
    }
  }
}
a {
  text-decoration: none;
}
.status-circle {
  width: 10px;
  height: 10px;
  min-width: 10px;
  border-radius: 15px;
  &.active {
    background: #a1de5f;
  }
  &.inactive {
    background: lightgray;
  }
}

.CommentInput {
  display: flex;

  &__SubmitButton {
    margin-left: auto;
    font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
  }
}
.activitiesReturn{
  margin-left: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  i {
    margin-right: 5px;
  }
}
</style>
